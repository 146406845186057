<template>
  <div style="min-height: 120px;" v-if="address">
    <span class="d-block">{{address.street}}</span>
    <span class="d-block">{{address.additional_street}}</span>
    <span class="d-block">{{address.postal_code}} {{address.city}}</span>
    <span class="d-block">{{address.administrative_area}}</span>
    <span class="d-block">{{countryFormatter(address.country)}}</span>
  </div>
  <span v-else>{{ $t('common.misc.NA') }}</span>
</template>

<script>
import formatter from "@/mixins/formatter";

export default {
  name: "Address",

  props: {
    address: {type: Object, default: () => {}}
  },

  mixins: [formatter]
}
</script>

<style scoped>

</style>